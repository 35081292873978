
import Select from '@/components/Select.vue';
import { defineComponent, PropType } from 'vue';
import { SaleListParams } from './index.vue';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import useEnableBidOptions from '@/mixins/useEnableBidOptions';
import PartnerFilter from '@/components/PartnerFilter.vue'

export default defineComponent({
  name: 'ListFilter',
  components: {
    Select,
    PartnerFilter
  },
  props: {
    params: {
      type: Object as PropType<SaleListParams>,
      required: true,
    },
    handleChangeFilterParams: { type: Function, required: true },
    onClickApply: { type: Function, required: true },
    onClickSearch: { type: Function, required: true },
    onClickRegister: { type: Function, required: true },
    isFilterOn: Boolean,
  },
  methods: {},
  setup(props) {
    const { categoryDepth1Options } = useCategoryOptions();
    const { enableBidOptions } = useEnableBidOptions();

    const saleOption = [
      { value: '', label: '전체' },
      { value: 'sale', label: '판매중' },
      { value: 'sold_out', label: '판매완료' },
      { value: 'bid_complete', label: '낙찰' },
      { value: 'time_out', label: '유찰' },
      { value: 'bid_payment_time_out', label: '낙찰취소' },
    ];

    const orderOption = [
      { value: '', label: '전체' },
      { value: 'deposit_wait', label: '입금대기중' },
      { value: 'payment_complete', label: '결제완료' },
      { value: 'payment_time_out', label: '결제시한만료' },
      { value: 'proccessed', label: '배송준비중' },
      { value: 'shipped', label: '배송중' },
      { value: 'delivered', label: '배송완료' },
      { value: 'purchase_cancel', label: '낙찰취소' },
      { value: 'return_request', label: '반품요청' },
      { value: 'complete', label: '구매확정' },
      { value: 'return_complete', label: '반품확정' },
    ];
    const updateParams = (key: string, value: any) => {
      console.log('updateParams', key, value);
      props.handleChangeFilterParams(key, value);
    };

    return {
      updateParams,
      enableBidOptions,
      orderOption,
      saleOption,
      categoryDepth1Options,
    };
  },
});
