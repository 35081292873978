<template>
  <table
    v-if="products"
    class="ListTable table-fixed w-full text-center text-sm mt-6"
  >
    <thead class="bg-gray-200">
    <tr class="h-10">
      <td class="w-44">
        <div class="flex justify-center items-center">
          LOT#
          <template v-if="sort.lot === 'NONE'">
            <button class="sorting-button" @click="sortHandler('lot','ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template  v-else-if="sort.lot === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('lot', 'DESC')">
              <sorting-ascending-icon />
            </button>
          </template>
          <template v-else-if="sort.lot === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('lot', 'NONE')">
              <sorting-descending-icon />
            </button>
          </template>
        </div>
      </td>
      <td class="">
        상품명<br>
        판매자
      </td>
      <td class="w-32">카테고리</td>
      <td class="w-32">
        <div class="flex justify-center items-center">
          판매기간
          <template v-if="sort.startDate === 'NONE'">
            <button class="sorting-button" @click="sortHandler('startDate', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template  v-else-if="sort.startDate === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('startDate', 'DESC')">
              <sorting-ascending-icon />
            </button>
          </template>
          <template v-else-if="sort.startDate === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('startDate', 'NONE')">
              <sorting-descending-icon />
            </button>
          </template>
        </div>
      </td>
      <td class="w-24">판매옵션</td>
      <td class="w-36">
        <div class="flex justify-center items-center">
          시작가
          <template v-if="sort.startPrice === 'NONE'">
            <button class="sorting-button" @click="sortHandler('startPrice', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template  v-else-if="sort.startPrice === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('startPrice', 'DESC')">
              <sorting-ascending-icon />
            </button>
          </template>
          <template v-else-if="sort.startPrice === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('startPrice', 'NONE')">
              <sorting-descending-icon />
            </button>
          </template>
        </div>
      </td>
      <td class="w-36">
        <div class="flex justify-center items-center">
          BUY NOW 가격
          <template v-if="sort.buynowPrice === 'NONE'">
            <button class="sorting-button" @click="sortHandler('buynowPrice', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template  v-else-if="sort.buynowPrice === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('buynowPrice', 'DESC')">
              <sorting-ascending-icon />
            </button>
          </template>
          <template v-else-if="sort.buynowPrice === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('buynowPrice', 'NONE')">
              <sorting-descending-icon />
            </button>
          </template>
        </div>
      </td>
      <td class="w-32">
        <div class="flex justify-center items-center">
          현재가(낙찰가)
          <template v-if="sort.nowPrice === 'NONE'">
            <button class="sorting-button" @click="sortHandler('nowPrice', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template  v-else-if="sort.nowPrice === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('nowPrice', 'DESC')">
              <sorting-ascending-icon />
            </button>
          </template>
          <template v-else-if="sort.nowPrice === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('nowPrice', 'NONE')">
              <sorting-descending-icon />
            </button>
          </template>
        </div>
      </td>
      <td class="w-20 ">
        <div class="flex justify-center items-center">
          Bid수
          <template v-if="sort.biddingCount === 'NONE'">
            <button class="sorting-button" @click="sortHandler('biddingCount', 'ASC')">
              <sorting-default-icon/>
            </button>
          </template>
          <template  v-else-if="sort.biddingCount === 'ASC'">
            <button class="sorting-button ascending" @click="sortHandler('biddingCount', 'DESC')">
              <sorting-ascending-icon />
            </button>
          </template>
          <template v-else-if="sort.biddingCount === 'DESC'">
            <button class="sorting-button descending" @click="sortHandler('biddingCount','NONE')">
              <sorting-descending-icon />
            </button>
          </template>
        </div>
      </td>
      <td class="w-32 text-xs">
        판매상태<br />
        주문상태
      </td>
      <td class="w-16 text-center">
        1:1문의
      </td>
      <td class="w-32">상태변경</td>
    </tr>
    </thead>
    <tbody>
    <tr
        class="h-20"
        v-for="(item, index) in products"
        :key="index"
    >
      <!-- 이미지 + LOT# -->
      <td @click="handleClickRow(item)" class="cursor-pointer">
        <div class="flex items-center">
          <div
            v-if="item.productImageList?.length > 0"
            class="w-15 h-15 bg-gray-100 bg-cover"
            :style="{
              backgroundImage: `url(${item.productImageList[0].url})`,
            }"
          ></div>
          <div
            v-else
            class="w-15 h-15 bg-gray-100 bg-cover"
            :style="{
              backgroundImage: `url(https://via.placeholder.com/120x120/F3F4F6?text=x)`,
            }"
          ></div>
          <div class="ml-4">{{ item.lot }}</div>
        </div>
      </td>

      <!-- 상품명 -->
      <td class="text-left cursor-pointer">
        <div @click="handleClickRow(item)">{{ item.artistName }}</div>
        <div @click="handleClickRow(item)">{{ item.title }}</div>
        <div
          v-if="!partnerSearched"
          class="inline-flex items-center mt-1 partner-item px-1 py-1"
          @click="$emit('searchPartner', { partnerId: item.partner.partnerId })"
        >
          <div
            class="w-5 h-5 bg-gray-100 bg-cover rounded-full mr-1"
            :style="{
              backgroundImage: `url(${item.partner.profileImage})`,
            }"
          ></div>
          <b class="text-xs">{{ item.partner.companyName }}</b>
        </div>
      </td>

      <!-- 카테고리 -->
      <td @click="handleClickRow(item)" class="cursor-pointer break-all text-xs">
        {{ categoryDepth1Obj[item.categoryDepth1Id] || '' }} > <br>
        {{ categoryDepth2Obj[item.categoryDepth2Id] || '' }}
      </td>

      <!-- 판매기간 -->
      <td @click="handleClickRow(item)" class="cursor-pointer text-xs">
        <div v-if="item.startDate">
          {{ format(new Date(item.startDate), 'yyyy/MM/dd HH:mm') }}
        </div>
        <div v-else>-</div>
        <div v-if="item.startDate && !item.buynowOnly">
          {{ format(new Date(item.endDate), 'yyyy/MM/dd HH:mm') }}
        </div>
        <div v-else>-</div>
      </td>

      <!-- 판매옵션 -->
      <td @click="handleClickRow(item)" class="cursor-pointer text-xs">
        <div v-if="item.enableBid">
          Bid Now<br>
          <mark v-if="item.enableBuyNow" class="sale-option__label">BUYNOW</mark>
        </div>
        <div v-else-if="item.buynowOnly">
          Buy Now Only<br>
          <mark v-if="item.buynowMakeOffer" class="sale-option__label red">MAKE OFFER</mark>
        </div>
        <div v-else>-</div>
      </td>

      <!-- 시작가 -->
      <td @click="handleClickRow(item)" class="cursor-pointer text-right text-xs">
        <p> {{ item.enableBid ? formatNumber(item.startPrice) : '-' }}</p>
      </td>

      <!--  buy now가격-->
      <td @click="handleClickRow(item)" class="cursor-pointer text-right text-xs">
        <p> {{ item.enableBuyNow || item.buynowOnly ? formatNumber(item.buynowPrice) : '-' }}</p>
      </td>

      <!-- 현재가(낙찰가) -->
      <td @click="handleClickRow(item)" class="cursor-pointer text-right text-xs">
        <div v-if="item.enableBid">
          <span v-if="item.order">{{ formatNumber(item.order.capitalPrice) }}</span>
          <p v-else-if="item.biddingCount > 0">
            <span>{{ formatNumber(item.nowPrice) }}</span>
          </p>
          <p v-else>
            <span>-</span>
          </p>
          <p class="text-xs" style="color: #FF0000;" v-if="item.order && item.order.orderType === 'buynow'">Buy Now 결제</p>
        </div>
        <div v-else-if="item.buynowOnly && item.order">
          <span>{{ formatNumber(item.buynowPrice) }}</span>
        </div>
        <div v-else>
          <span>-</span>
        </div>
      </td>
      <!--        Bid수-->
      <td @click="handleClickRow(item)" class="cursor-pointer text-right">
        <template v-if="item.enableBid">
          <p class="text-xs" style="color: #171717">{{ biddingCount(item.biddingCount) }}</p>
        </template>
        <template v-else>
          -
        </template>
      </td>


      <!-- 상태 -->
      <td @click="handleClickRow(item)" class="cursor-pointer">
        <mark :class="`sale-status__label ${salesStatus[item.salesStatus]?.colorClass}`">
          {{ salesStatus[item.salesStatus]?.label }}
        </mark>
        <p>
          <span v-if="item.orderStatus === 'none'" class="text-xs">-</span>
          <mark v-else :class="`status__label ${orderStatus[item.orderStatus]?.colorClass}`">
            {{ orderStatus[item.orderStatus]?.label }}
          </mark>
        </p>
        <p style="font-size: 10px;" v-if="item.orderHistory && item.orderHistory.length > 0">
          {{ format(new Date(item.orderHistory[0].updatedAt), 'yyyy/MM/dd HH:mm') }}
        </p>
      </td>

      <td class="cursor-pointer text-center">
        <router-link :to="`/app/qna?productId=${item.productId}`" v-if="item.qnaCount && item.qnaCount > 0">
          <mark class="status__label red-full">1:1문의</mark>
        </router-link>
      </td>
      <!-- 상태변경 -->
      <td>
        <select
          class="
            FormSelect
            h-10
            border
            inline-flex
            px-4
            appearance-none
            focus:outline-none
            rounded-none
            text-sm
            w-30
          "
          @input="onChangeStatus($event, item)"
        >
          <option value="">상태 변경</option>
          <option value="payment_complete">결제완료</option>
          <option value="proccessed">배송준비중</option>
          <option value="shipped">배송중</option>
          <option value="delivered">배송완료</option>
          <option value="purchase_cancel">낙찰취소</option>
          <option value="return_request">반품요청</option>
          <option value="complete">구매확정</option>
          <option value="return_complete">반품확정</option>
        </select>
      </td>
    </tr>
    <tr>
      <SetInvoiceNumberModal
        @onClose="() => closeInvoiceNumber()"
        :isOpen="isOpenInvoiceNumber"
        :order="targetProduct && targetProduct.order"
        :okHandler="() => changeStatus('shipped', targetProduct.productId)"
      />
    </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, onUpdated, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import SetInvoiceNumberModal from '@/components/SetInvoiceNumberModal.vue';
import partnerAPI from '@/service/partnerAPI';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import salesAndOrderStatus from '@/constants/salesAndOrderStatus';
import SortingDefaultIcon from '@/components/icons/SortingDefaultIcon.vue';
import SortingDescendingIcon from '@/components/icons/SortingDescendingIcon.vue';
import SortingAscendingIcon from '@/components/icons/SortingAscendingIcon.vue';

export default defineComponent({
  name: 'ListTable',
  components: {
    SetInvoiceNumberModal,
    SortingAscendingIcon,
    SortingDefaultIcon,
    SortingDescendingIcon
  },
  props: {
    products: Object as PropType<any>,
    partnerSearched: {
      type: Boolean,
      default: false
    },
    sortBy: String
  },
  setup: function (props, { emit }) {
    const { categoryDepth1Obj, categoryDepth2Obj } = useCategoryOptions();
    const { salesStatus, orderStatus } = salesAndOrderStatus();
    const isOpenInvoiceNumber = ref(false);
    const targetProduct = ref(null);
    const sort = ref(
        {
          lot: 'NONE',
          startDate: 'NONE',
          startPrice: 'NONE',
          buynowPrice: 'NONE',
          biddingCount: 'NONE',
          nowPrice: 'NONE',
        }
    )
    if (props.sortBy) {
      const [key, value] = props.sortBy.split(':')
      if (key && value) {
        sort.value[key] = value
      }
    }

    const sortHandler= (name, value) => {
      Object.keys(sort.value).forEach(key => sort.value[key] = 'NONE')
      sort.value[name] = value
      if (value === 'NONE') {
        emit('changeSort', undefined)
        return
      }
      emit('changeSort', `${name}:${value}`)
    }

    const closeInvoiceNumber = () => {
      isOpenInvoiceNumber.value = false;
    };
    onUpdated(() => {
      console.log('products', props.products);
    });

    // 상태변경
    const onChangeStatus = (e, item) => {
      const value = e.target.value;
      if (value) {
        if (value === 'shipped') {
          targetProduct.value = item;
          isOpenInvoiceNumber.value = true;
        } else {
          console.log(item.orderStatus);
          if (
              window.confirm(
                  `[${orderStatus[item.orderStatus].label}] -> [${orderStatus[value].label}] 로 변경하시겠습니까?`
              )
          ) {
            changeStatus(value, item.productId);
          }
        }
      }
      e.target.value = '';
    };

    const changeStatus = async (option, productId) => {
      try {
        console.log(option);
        const { data } =
            await partnerAPI.adminSales.adminOrderStatusUpdate({
              param: {
                productId,
                orderStatus: option,
              },
            });
        alert((data as any).message);
        emit('refresh');
      } catch (e) {
        console.error(e.response.data);
        alert(getServerErrorMessage(e));
      }
    };

    const handleClickRow = (item) => {
      // 상품상세로 이동
      router.push({
        path: `/app/sales/detail/${item.productId}`,
      });
    };

    const biddingCount = (count) => {
      return count > 0 ? `Bid : ${count} 건` : 'Bid : 없음';
    };

    const formatNumber = (number) => {
      if (number === 0) {
        return '0원';
      }
      if (!number) {
        return '-';
      }
      return number.toLocaleString() + '원';
    };

    return {
      sort,
      sortHandler,
      targetProduct,
      format,
      biddingCount,
      isOpenInvoiceNumber,
      onChangeStatus,
      handleClickRow,
      closeInvoiceNumber,
      changeStatus,
      categoryDepth1Obj,
      categoryDepth2Obj,
      formatNumber,
      salesStatus,
      orderStatus,
    };
  },
});
</script>

<style lang="scss" scoped>
.ListTable {
  thead {
    tr {
      border: 1px solid #DDDDDD;
    }
  }
  tbody {
    tr {
      border-bottom: 1px dashed #dddddd;
    }
  }

  td {
    padding: 8px 10px;
  }
  .partner-item {
    &:hover {
      background: #CFF7FB;
    }
  }
}
</style>
