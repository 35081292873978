
import { defineComponent, ref, onMounted, watch, reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ExcelDownIcon from '@/components/icons/ExcelDownIcon.vue';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format/index.js';
import datePattern from '@/constants/datePattern';
import _ from 'lodash'
import XLSX from 'xlsx'
import convertNumWithComma from "@/utils/convertNumWithComma";
import getServerErrorMessage from "@/utils/getServerErrorMessage";
import useCategoryOptions from "@/mixins/useCategoryOptions";
import salesAndOrderStatus from '@/constants/salesAndOrderStatus';

export type SaleListParams = {
  partnerId?: number
  start?: string;
  end?: string;
  categoryDepth1Id?: number;
  orderStatus?: any;
  salesStatus?: any;
  enableBid?: boolean;
  keyword?: string;
  biddingExist?: boolean;
  page: number;
  pageSize: number;
  sortBy?: string
};

export default defineComponent({
  name: 'Sales',
  components: {
    Container,
    ListFilter,
    ListTable,
    Pagination,
    ExcelDownIcon
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;

    const filterParams = reactive<SaleListParams>({
      partnerId: Number(query.partnerId) || undefined,
      start: (query.start as string) || '',
      end: (query.end as string) || '',
      categoryDepth1Id: Number(query.categoryDepth1Id) || undefined,
      orderStatus: (query.orderStatus as string) || '',
      salesStatus: (query.salesStatus as string) || '',
      enableBid: query.enableBid === 'true' ? true : query.enableBid === 'false' ? false : undefined,
      keyword: (query.keyword as string) || '',
      biddingExist: query.biddingExist === 'true' ? true : undefined,
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
      sortBy: (query.sortBy as string) || undefined,
    });

    const isFilterOn = ref(query.isFilter === 'true');

    const productList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });

    const toggleApplyFilters = () => {
      isFilterOn.value = !isFilterOn.value;
      executeSearch();
    };

    const handleChangeFilterParams = (key: string, value: any) => {
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    const handleClickSearch = () => {
      console.log('베르나');
      filterParams.page = 0;
      executeSearch();
    };

    const searchPartner = ({ partnerId }) => {
      console.log('서치파트너', partnerId)
      filterParams.partnerId = partnerId
      filterParams.page = 0
      executeSearch()
    }

    const removeSearchPartner = () => {
      filterParams.partnerId = undefined
      filterParams.page = 0
      executeSearch()
    }

    const handleChangeSort = (sortBy) => {
      filterParams.sortBy = sortBy
      executeSearch()
    }

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      //fetchSalesLists();
      router.push({
        path: window.location.pathname,
        query: {
          partnerId: filterParams.partnerId || '',
          start: filterParams.start || '',
          end: filterParams.end || '',
          categoryDepth1Id: filterParams.categoryDepth1Id || '',
          orderStatus: filterParams.orderStatus || '',
          salesStatus: filterParams.salesStatus || '',
          enableBid: String(filterParams.enableBid) || '',
          keyword: filterParams.keyword || '',
          biddingExist: String(filterParams.biddingExist) || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
          isFilter: String(isFilterOn.value),
          sortBy: String(filterParams.sortBy) || ''
        },
      });
    };

    const handleClickRegister = () => {
      router.push({
        path: '/app/product/register',
        query: {
          step: 1,
        },
      });
    };

    const getAppliedFilterParams = () =>
        isFilterOn.value
            ? {
              ...filterParams,
              start: filterParams.start
                  ? format(
                      new Date(filterParams.start),
                      datePattern.INPUT_DATE + ' 00:00:00'
                  )
                  : undefined,
              end: filterParams.end
                  ? format(
                      new Date(filterParams.end),
                      datePattern.INPUT_DATE + ' 23:59:59'
                  )
                  : undefined,
            }
            : {
              // 필터가 적용되어있지 않으면 키워드와 파트너아이디, 정렬만 사용한다
              partnerId: filterParams.partnerId,
              sortBy: filterParams.sortBy,
              keyword: filterParams.keyword,
              page: filterParams.page,
              pageSize: filterParams.pageSize,
            };

    const fetchSalesLists = async () => {
      console.group('fetchSalesLists');
      console.log('finalFilterParams', getAppliedFilterParams());
      console.groupEnd();

      try {
        const { data } = await partnerAPI.adminSales.adminSalesList(
          getAppliedFilterParams()
        );
        productList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    const startRef = toRef(filterParams, 'start');
    const endRef = toRef(filterParams, 'end');
    const categoryIdRef = toRef(filterParams, 'categoryDepth1Id');
    const orderStatusRef = toRef(filterParams, 'orderStatus');
    const salesStatusRef = toRef(filterParams, 'salesStatus');
    const enableBidRef = toRef(filterParams, 'enableBid');
    const biddingExistRef = toRef(filterParams, 'biddingExist');

    watch(
      [isFilterOn, startRef, endRef, categoryIdRef, orderStatusRef, salesStatusRef, enableBidRef, biddingExistRef],
      ([isFilterOn]) => {
        // 필터 활성화 상태에서 watch하는 파라미터가 업데이트되면 검색 실행
        if (isFilterOn) {
          executeSearch();
        }
      }
    );

    onMounted(fetchSalesLists);

    watch([filterParams, isFilterOn], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchSalesLists();
    });


    const excel = () => {
      const { categoryDepth1Obj } = useCategoryOptions();

      const handleExcelDownload = _.throttle(async () => {
        try {
          const { data } = await partnerAPI.adminSales.adminSalesList(
              {
                ...getAppliedFilterParams(),
                page: 0,
                pageSize: 100000,
              }
          )
          const list = (data as any).data
          if (list.length === 0) {
            alert('다운로드할 데이터가 없습니다.')
            return
          }
          makeExcel(list)
        } catch (e) {
          console.error(e)
          alert(getServerErrorMessage(e)|| '엑셀 생성 실패')
        }
      }, 10000)

      const makeExcel = (saleList) => {
        const { orderStatus } = salesAndOrderStatus();
        const transformed = saleList.map((product) => {
          return {
            'LOT#': product.lot,
            '상품명': product.title,
            '작가명(브랜드명)': product.artistName || product.brandName,
            '판매자(파트너)상호': product.partner?.companyName,
            '카테고리': categoryDepth1Obj.value[product.categoryDepth1Id] || '',
            '판매기간': `${product.startDate ? format(new Date(product.startDate), 'yyyy/MM/dd HH:mm') : '-'} ~ ${product.endDate ? format(new Date(product.endDate), 'yyyy/MM/dd HH:mm') : '-'}`,
            '판매옵션': `${product.enableBid ? 'Bid Now' : 'Buy Now Only'}${product.enableBuyNow ? '(Buy Now)' : ''}`,
            '시작가': convertNumWithComma(product.startPrice, ''),
            'Buy Now가격': convertNumWithComma(product.buynowPrice, ''),
            '현재가(낙찰가)': convertNumWithComma(product.nowPrice, ''),
            'Bid수': convertNumWithComma(product.biddingCount, ''),
            '주문상태': orderStatus[product.orderStatus]?.label,
            '결제금액': convertNumWithComma(product.order?.totalPrice || 0 + product.order?.totalShippingFee || 0, ''),
            '결제수단': product.order?.payMethod === 'CARD' ? '신용카드' : product.order?.payMethod === 'VBANK' ? '가상계좌' : '',
            '결제완료시간': product.order?.payDate ? format(new Date(product.order?.payDate), 'yyyy/MM/dd HH:mm') : '',
            '구매확정시간': product.order?.completeDate ? format(new Date(product.order?.completeDate), 'yyyy/MM/dd HH:mm') : '',
            '구매자이름': product.order?.buyerName,
            '구매자전화번호': product.order?.buyerMobile,
            '구매자이메일': product.order?.buyerEmail,
            '받으실분 이름': product.order?.name,
            '받으실분 전화번호': product.order?.tel1,
            '배송주소': product.order ? `${product.order?.address1} ${product.order?.address2}` : '',
          }
        })
            .sort((p, n) => p['구매확정시간'] < n['구매확정시간'] ? 1 : -1)
            .sort((p, n) => p['결제완료시간'] < n['결제완료시간'] ? 1 : -1)


        const dataWS = XLSX.utils.json_to_sheet(transformed)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS, '판매현황')
        XLSX.writeFile(wb, `판매현황_목록_${format(new Date(), 'yyyyMMddHHmm')}.xlsx`)
      }

      return {
        handleExcelDownload
      }
    }


    return {
      filterParams,
      productList,
      toggleApplyFilters,
      handleChangePage,
      handleChangePageSize,
      handleChangeSort,
      searchPartner,
      removeSearchPartner,
      executeSearch,
      handleClickRegister,
      handleClickSearch,
      isFilterOn,
      handleChangeFilterParams,
      fetchSalesLists,
      ...excel()
    };
  },
});
